import * as React from "react";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import IconCloseModal from "./icons/IconCloseModal";
import ReactMarkdown from 'react-markdown';

const CandidateModal = ({candidate, meetInfo, ward, setModalOpen}) => {

  const name = candidate.candidate_name;
  const email = candidate.candidate_email;
  const phone = candidate.telephone_number;
  const statement = candidate.candidate_statement;
  const party = candidate.candidate_party.toLowerCase();
  const image = getImage(candidate.candidate_profile_picture);

  return(
    <div className="overflow-steps overflow-steps--candidate">
      <IconCloseModal setModalOpen={setModalOpen} />
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <GatsbyImage
              image={image}
              objectFit="cover"
              alt=""
              placeholder="blurred"
            />
          </div>
          <div className="col-md-7">
            <div className="row candidate__data">
              <h3 className="candidate__name">{name}</h3>
              <h4 className="candidate__ward">Candidate for {ward}</h4>
              <span><p className={`candidate__party ${party?.replace(/\s+/g, '-').replace("'", '').toLowerCase()}`}>{party}</p></span>
            </div>
            <div className="col-md-9">
              {
                statement
                 &&
                <div className="row candidate__statement">
                  <h4 className="candidate__headline">Candidate Statement</h4>
                 
                  <ReactMarkdown className="statement">{ statement }</ReactMarkdown>
                  <div className="social-network">

                    {
                      candidate.website
                        &&
                      <a className="candidate__social-link" href={( candidate.website.includes('https://') || candidate.website.includes('http://') )? candidate.website : `https://${candidate.website}`} target="_blank" rel="noopener">Website</a>
                    }
                    {
                      candidate.twitter
                        &&
                      <a className="candidate__social-link" href={candidate.twitter.includes('https://') ? candidate.twitter : `https://${candidate.twitter}`} target="_blank" rel="noopener">Twitter</a>
                    }
                    {
                      candidate.linkedin
                        &&
                      <a className="candidate__social-link" href={candidate.linkedin.includes('https://') ? candidate.linkedin : `https://${candidate.linkedin}`} target="_blank" rel="noopener">LinkedIn</a>
                    }
                    
                    {
                      candidate.social_media && candidate.social_media.map(media => (
                        <a className="candidate__social-link" href={media.media_url.includes('https://') ? media.media_url : `https://${media.media_url}`} target="_blank" rel="noopener">{media.media_label}</a>
                      ))
                    }
                  </div>
                </div>
              }
              {
                (phone || email)
                  &&
                <div className="row candidate__contact">
                  <h4 className="candidate__headline">Contact Details</h4>
                  {
                    phone 
                      &&
                    <div className="candidate__phone">
                      <span className="contact-label">Phone:</span>
                      <a href={`tel:${phone}`}>{phone}</a>
                    </div>
                  }
                  {
                    email 
                    &&
                    <div className="candidate__mail">
                      <span className="contact-label">Mail:</span>
                      <a href={`mailto:${email}`}>{email}</a>
                    </div>
                  }
                </div>
              }
              {
                meetInfo
                  &&
                <div className="row candidate__meetings">
                  <h4 className="candidate__headline">Meet your candidate</h4>
                  <ReactMarkdown className="meetings">{ meetInfo }</ReactMarkdown>
                </div>
              }
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateModal;