
import * as React from "react";
import { useState } from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import IconArrow from "../components/icons/IconArrow";

export default function WardElectionsHero({ electionType, headerInformation, wardElectionDate, wardName, wardImage, candidatesNumber, wardVotingPlaces, handleClick}) {
  const image = getImage(wardImage);

  const byPostMessage = headerInformation?.by_post_message;
  const showByPostButton = headerInformation?.show_by_post_button;
  const proxyVoterMessage = headerInformation?.proxy_voter_message;
  const showProxyVoterButton= headerInformation?.proxy_voter_button;


  return (
    <div className="hero--ward">
        <div className="container container--elections">
          <div className="row">

            <div className="col-md-6 col-left">
              <div className="col-md-10">
                <h1>{wardName}</h1>
                {
                  // candidatesNumber && <p>The ward of {wardName} elects {candidatesNumber} Common Councillors</p>
                }
                <Link href="#meet-your-candidates">
                  <button>Meet your candidates</button>
                </Link>
              </div>

              <GatsbyImage
                image={image}
                className="hero--ward__picture"
                objectFit="cover"
                alt=""
                placeholder="blurred"
              />
            </div>
            <div className="col-md-6 col-right">
              <div className="bg-margin"></div>
              <h2 className="election-type">Upcoming election for {electionType}: {wardElectionDate}</h2>
              <h2>How can I vote?</h2>

              <div className="ward-info">
                <h3> <IconArrow/> In person {wardElectionDate && `on ${wardElectionDate }`}</h3>
                  {
                    wardVotingPlaces.map(place => (
                      <>
                        <p className="ward-info__place">{place.location_name}</p>
                        <div className="ward-info__content ward-info__address">
                          <div className="address">
                            <p>{place.location_address}</p>
                            <p>Polls open: 8am to 8pm</p>
                          </div>
                          {
                            place.location_url
                              &&
                            <a href={place.location_url} target="_blank" rel="noopener noreferrer">
                              <button>See map</button>
                            </a>
                          }
                        </div>
                      </>
                    ))
                  }
              </div>

              {
                byPostMessage
                  &&
                <div className="ward-info">
                  <h3> <IconArrow/> By post</h3>
                  <div className="ward-info__content">
                    <p>{byPostMessage}</p>
                    {/* <p>If you would prefer to vote by post, your application must be signed by hand and received by 5pm on 9th March.</p> */}
                    {
                      showByPostButton
                        &&
                      <div className="button-wrapper">
                        <button id="pdf-form" onClick={(e) => handleClick(e)}>Apply now</button>
                      </div>
                    }
                  </div>
                </div>
              }

              {
                proxyVoterMessage
                  &&
                <div className="ward-info">
                  <h3> <IconArrow/> Appoint a proxy voter</h3>
                  <div className="ward-info__content">
                    <p>{proxyVoterMessage}</p>
                    {/* <p>If you are unable to get to the polling station, you can appoint someone else to vote on your behalf.</p> */}
                    {
                      showProxyVoterButton 
                        ?
                      <div className="button-wrapper">
                        <button id="proxy-modal" onClick={(e) => handleClick(e)}>More info</button>
                      </div>
                        :
                      <a class="button-link" href=" https://www.speakforthecity.com/voting"><button>More info</button></a>
                    }
                  </div>
                </div>
              }
              
            </div>
          </div>
        </div>
      </div>
  )
}
