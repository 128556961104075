
import * as React from "react";
import { useState } from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import IconArrow from "../components/icons/IconArrow";

export default function WardNoElectionsHero({ noElectionsMessage, wardName, wardImage, candidatesNumber, wardVotingPlaces, handleClick}) {
  const image = getImage(wardImage);

  return (
    <div className="hero--ward hero--ward--no-elections">
        <div className="container">
          <div className="row row--no-elections">

            <div className="col-md-12">
              <h1>{wardName}</h1>
              <p>{noElectionsMessage}</p>

              <GatsbyImage
                image={image}
                className="hero--ward__picture hero--ward__picture--fill"
                objectFit="cover"
                alt=""
                placeholder="blurred"
              />
            </div>
          </div>
        </div>
      </div>
  )
}
